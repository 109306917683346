import React from "react"
import { graphql } from "gatsby"
import moment from 'moment';
import Layout from "../components/layout";
import SEO from "../components/seo";

export default ({ data }) => {
    const releaseNotes = data.allReleaseNotesWebsite.edges.map((note, index) => {
        if (note.node.releasedatum.length != null && note.node.releasedatum.length > 0 && (note.node.releasedatum.indexOf('z') < 0 && note.node.releasedatum.indexOf('Z') < 0) && note.node.releasedatum.indexOf('-') > -1) {
            const splittedDate = note.node.releasedatum.split('-');
            note.node.releasedatum = splittedDate[1] + '-' + splittedDate[0] + '-' + splittedDate[2];
        } else if (note.node.releasedatum.length != null && note.node.releasedatum.length > 0 && note.node.releasedatum.indexOf('z') < 0 && note.node.releasedatum.indexOf('Z') < 0) {
            note.node.releasedatum = moment.unix(note.node.releasedatum / 1000).toDate();
        }
        return (
            <tr key={index}>
                <td className="extra-width">{moment((note.node.releasedatum.length != null && note.node.releasedatum.length > 0 && ((note.node.releasedatum.indexOf('z') > -1 && note.node.releasedatum.indexOf('Z') > -1) || note.node.releasedatum.indexOf('-') > -1) ? note.node.releasedatum : Number(note.node.releasedatum))).format('DD-MM-yyyy')}</td>
                <td>{note.node.branche}</td>
                <td>{note.node.ticketNummer}</td>
                <td>{note.node.titel}</td>
                <td>{note.node.module1} {note.node.module2}</td>
                <td>{note.node.omschrijving}</td>
            </tr>
        );
    });

    let metaDescriptionValue = '';

    if (data.gcms && data.gcms.titles && data.gcms.metaDescriptions.length > 0) {
        for (const metaDescription of data.gcms.metaDescriptions) {
            if (metaDescription.indicator === 'release-notes-meta-description') {
                metaDescriptionValue = metaDescription.value;
            }
        }
    }

    return (
        <Layout>
            <SEO title="Release Notes" description={metaDescriptionValue} urlParams="release-notes" />
            <img className="print print-image" src='https://media.travelspirit.nl/original/sm/TravelSpirit_Logo-FC.png' alt="logo" loading="lazy" />
            <div className="release-notes-container">
                <h1>Release Notes</h1>
                <table>
                    <thead>
                        <tr>
                            <td className="extra-width"><b>Datum</b></td>
                            <td><b>Branche</b></td>
                            <td><b>Ticketnummer</b></td>
                            <td><b>Titel</b></td>
                            <td><b>Module</b></td>
                            <td><b>Omschrijving</b></td>
                        </tr>
                    </thead>
                    <tbody>{releaseNotes}</tbody>
                </table>
            </div>
        </Layout>
    );
}

export const query = graphql`
query {
    allReleaseNotesWebsite(filter: {branche: {ne: ""}, opSite_: {eq: "Ja"}}) {
        edges {
             node {
                id
                titel
                ticketNummer
                omschrijving
                module1
                module2
                releasedatum
                branche
            }
        }
    }
    gcms {
        metaDescriptions {
            indicator
            value
        }
    }
}
`